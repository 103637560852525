// src/pages/CategoryPage.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HorizontalForm from '../components/HorizontalForm';
import './CategoryPage.css'; // Optional: Create a CSS file for custom styles

const CategoryPage = () => {
    const { id } = useParams(); // Get the category ID from the URL
    const [categoryData, setCategoryData] = useState(null);
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        message: '',
    });

    useEffect(() => {
        const fetchCategoryData = async () => {
            try {
                const response = await axios.get(`https://www.yesmigon.co.il/api/index.php?action=appcategory&identifier=${id}&lang=he`);
                setCategoryData(response.data.data);
                if (response.data.data.title) {
                    document.title = response.data.data.title;
                }
            } catch (error) {
                console.error('Error fetching category data:', error);
            }
        };

        fetchCategoryData();
    }, [id]);

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://comark-yesmigon.localhost/api/index.php?action=priceRequest',
                new URLSearchParams({
                    name: formData.name,
                    phone: formData.phone,
                    message: formData.message
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            if (response.data.status === 200) {
                alert('הבקשה נשלחה בהצלחה!');
                setFormData({ name: '', phone: '', message: '' }); // Clear the form
            } else {
                alert('אירעה שגיאה בעת שליחת הבקשה. אנא נסה שנית.');
            }
        } catch (error) {
            console.error('Error submitting price request:', error);
            alert('אירעה שגיאה בעת שליחת הבקשה.');
        }
    };

    if (!categoryData) {
        return <div>Loading...</div>; // Loading state
    }

    return (
        <>
            <Helmet>
                <title>{categoryData.title}</title>
                <meta name="description" content={categoryData.title} />
                <meta name="keywords" content={categoryData.title} />
            </Helmet>
            <Header categories={categoryData.categories} />
            <div
                className="header-base parallax"
                style={{
                    backgroundImage: `url('/images/cat.jpg')`, // Set your desired parallax image here
                }}>
                <div className="overlay" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-base text-right">
                                <h1>{categoryData.title}</h1>
                                <p>
                                    <ol className="breadcrumb b white">
                                        <li><Link to="/">ראשי</Link></li>
                                        <li>{categoryData.title}</li>
                                    </ol>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section-empty bg-white mt-50 mb-50">
                <div className="container">
                    <div className="row">
                        {categoryData.articles.map((article) => (
                            <div key={article.ID} className="col-lg-3 col-md-3 col-sm-6 col-6 mb-3">
                                <div className="article-box">
                                    <Link to={`/article/${article.ID}`}>
                                        <img
                                            src={article.picture ? `http://www.yesmigon.co.il/${article.picture}` : 'placeholder.jpg'}
                                            alt={article.title}
                                            className="img-fluid article-image"
                                        />
                                        <div className="article-title">
                                            <h2>{article.title}</h2>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <HorizontalForm formData={formData} handleFormChange={handleFormChange} handleFormSubmit={handleFormSubmit} />
            <Footer />
        </>
    );
};

export default CategoryPage;
