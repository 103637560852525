import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import MainBanner from '../components/MainBanner';
import Specializing from '../components/Specializing';
import MainContent from '../components/MainContent';
import ParallaxSection from '../components/ParallaxSection';
import ArticlesSection from '../components/ArticlesSection';
import Footer from '../components/Footer';

const Homepage = () => {
  const [categories, setCategories] = useState([]);
  const [settings, setSettings] = useState({});
  const [articles, setArticles] = useState([]); // New state for articles

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://www.yesmigon.co.il/api/index.php?action=apphome&lang=he');
        setCategories(response.data.data.categories);
        setSettings(response.data.data.settings);
        setArticles(response.data.data.articles); // Set articles from the response

        if (response.data.data.settings.siteName) {
          document.title = response.data.data.settings.siteName;
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Header categories={categories} />
      <MainBanner settings={settings} />
      <Specializing />
      <MainContent />
      <ParallaxSection parallaxImage={settings.parallaxImage} siteUrl={settings.siteUrl} />
      <ArticlesSection articles={articles} /> {/* Pass articles to ArticlesSection */}
      <Footer settings={settings} />
    </>
  );
};

export default Homepage;
