import React from 'react';
import { Parallax } from 'react-parallax';

const ParallaxSection = ({ parallaxImage, siteUrl }) => {
  const isIOS = () => {
    return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  };

  return (
    <Parallax
      bgImage={parallaxImage ? `${siteUrl}${parallaxImage.replace('..', '')}` : `${siteUrl}/images/1.webp`}
      strength={isIOS() ? 50 : 150}
      bgImageStyle={{
        objectFit: 'cover',
        width: '100%',
        height: '100%',
        willChange: 'transform',
      }}
    >
      <div className="section-bg-image parallax-window white" style={{ minHeight: '400px' }}>
        <div className="container content">
          <div className="row vertical-row">
            <div className="col-md-6 text-right-xs">
              <h2 className="text-color bannerWhite-shadow">הבית הוא המקום שכל היקר שלנו נמצא בו, הגנו עליו!</h2>
              <p class="bannerWhite-shadow">
              מגוון רחב של פתרונות שערים חשמליים, שערים קונזולים, כנפיים, ושערי הזזה מותאים לבתים פרטיים, בניינים משותפים, משרדים ומושבים.
              </p>
             
            </div>
            <div className="col-md-6 text-center text-right-xs">
              <a href="/article/5" className="btn btn-border btn-lg">
                מנועים לשערי כנף
              </a>
            </div>
          </div>
        </div>
      </div>
    </Parallax>
  );
};

export default ParallaxSection;
