import React from 'react';
import Section from './Section';

const MainContent = () => {
  return (
    <div className="section-empty bg-white mb-50 ">
      <div className="container ">
        <div className="row">
          <Section 
            title="גלרית פרוייקטים מוצלחים"
            text="נשמח להראות לכם מעל 1000+ עבודות מוצלחות שביצענו ללקוחות מרוצים, כולל שערים קונזוליים, שערי כנפיים, ותריסי גלילה באיכות הגבוהה ביותר. אנו מבטיחים לכם שילוב של איכות, אמינות ושירות ללא פשרות."
            items={[
              "מושבים וקיבוצים",
              "משרדים ומוסדות",
              "בנייני מגורים"
            ]}
            buttonText="פרופיל החברה"
          />
          <div className="col-md-3">
            <div className="img-overlay-container">
              <a className="img-box thumbnail lightbox " href="/article/10">
                <span><img src="images/3.jpg" alt="תריסי גלילה" /></span>
              </a>
              <h5 className="overlay-text">תריסי גלילה</h5>
            </div>
          </div>
          <div className="col-md-3">
            <div className="img-overlay-container">
              <a className="img-box thumbnail lightbox " href="/article/7">
                <span><img src="images/2.jpeg" alt="מנועים לשערים" /></span>
              </a>
              <h5 className="overlay-text">מנועים לשערים</h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainContent;
