// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import About from './pages/About';
import ArticlePage from './pages/ArticlePage';
import CategoryPage from './pages/CategoryPage'; // Import the new CategoryPage component

import 'bootstrap/dist/css/bootstrap.rtl.min.css';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null; // This component does not render anything
};

const App = () => {
  return (
    <Router>
      <ScrollToTop /> {/* Add ScrollToTop component */}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/about" element={<About />} />
        <Route path="/article/:id" element={<ArticlePage />} />
        <Route path="/category/:id" element={<CategoryPage />} /> {/* Add route for category */}
      </Routes>
    </Router>
  );
};

export default App;
