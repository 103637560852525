import React, { useState } from 'react';
import './HorizontalForm.css'; // Ensure you have the CSS file for the horizontal form
import axios from 'axios';

const HorizontalForm = () => {
    const [formData, setFormData] = useState({
        name: '',
        phone: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://www.yesmigon.co.il/api/index.php?action=priceRequest',
                new URLSearchParams({
                    name: formData.name,
                    phone: formData.phone,
                    message: formData.message
                }),
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            if (response.data.status === 200) {
                alert('הבקשה נשלחה בהצלחה!');
                setFormData({ name: '', phone: '', message: '' }); // Clear form after submission
            } else {
                alert('אירעה שגיאה בעת שליחת הבקשה. אנא נסה שנית.');
            }
        } catch (error) {
            console.error('Error submitting price request:', error);
            alert('אירעה שגיאה בעת שליחת הבקשה.');
        }
    };

    return (
        <div className="horizontal-form-container">
            <div className="container">
                <h3 className="form-title">צרו קשר איתנו לקבלת הצעת מחיר ויעוץ חינמי</h3>
                <div className="form-wrapper">
                    <form onSubmit={handleSubmit} className="horizontal-form">
                        <div className="form-group">
                            <label htmlFor="name">שם</label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                placeholder="הכנס את שמך"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="phone">טלפון</label>
                            <input
                                type="tel"
                                id="phone"
                                name="phone"
                                value={formData.phone}
                                onChange={handleChange}
                                placeholder="הכנס את מספר הטלפון שלך"
                                required
                            />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">הודעה</label>
                            <input
                                id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange}
                                placeholder="הכנס את ההודעה שלך"

                                required
                            />
                            
                        </div>
                        <button type="submit" className="submit-btn">שלח</button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default HorizontalForm;
