import React from 'react';
import './Footer.css';
import { FaFacebookF, FaInstagram, FaYoutube, FaWhatsapp, FaTiktok } from 'react-icons/fa';

const Footer = ({ settings }) => {
  const logoUrl = settings?.logoImage ? `${settings.siteUrl}${settings.logoImage.replace('..', '')}` : 'images/logo.png';

  return (
    <footer className="footer-base">
      <div className="container content">
        <div className="row">
          <div className="col-md-4">
            <img className="logo" src={logoUrl} alt="logo" />
            <hr className="space s" />
            <p className="text-s">{settings?.footerText || 'Default footer text'}</p>
            <hr className="space s" />
            <div className="btn-group social-group btn-group-icons">
              {settings?.footerSocialMediaFacebook && (
                <a target="_blank" rel="noopener noreferrer" href={settings.footerSocialMediaFacebook} title="Facebook">
                  <FaFacebookF className="text-s circle social-icon" />
                </a>
              )}
              {settings?.footerSocialMediaInstagram && (
                <a target="_blank" rel="noopener noreferrer" href={settings.footerSocialMediaInstagram} title="Instagram">
                  <FaInstagram className="text-s circle social-icon" />
                </a>
              )}
              {settings?.footerSocialMediaYoutube && (
                <a target="_blank" rel="noopener noreferrer" href={settings.footerSocialMediaYoutube} title="YouTube">
                  <FaYoutube className="text-s circle social-icon" />
                </a>
              )}
              {settings?.footerSocialMediaWhatsapp && (
                <a target="_blank" rel="noopener noreferrer" href={settings.footerSocialMediaWhatsapp} title="WhatsApp">
                  <FaWhatsapp className="text-s circle social-icon" />
                </a>
              )}
              {settings?.footerSocialMediaTiktok && (
                <a target="_blank" rel="noopener noreferrer" href={settings.footerSocialMediaTiktok} title="TikTok">
                  <FaTiktok className="text-s circle social-icon" />
                </a>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <h3 className="text-black text-uppercase">צור קשר</h3>
            <hr className="space space-30" />
            <ul className="fa-ul text-s ul-squares">
              <li>טלפון: 054-785-5546              </li>
              <li>{settings?.contactEmail || 'defaultemail@domain.com'}</li>
              <li>
              ימים א'-ה': 9:00 - 18:00, יום ו': 9:00 - 13:00              </li>
            </ul>
          </div>
          <div className="col-md-4">
            <h3 className="text-black text-uppercase">קישורים חשובים</h3>
            <hr className="space space-30" />
            <div className="footer-menu text-s">
              <a href="/article/7">מנועים לשערים חשמליים</a>
              <a href="/article/10  ">תריסי גלילה</a>
              <a href="/article/1">
              אודות יס-מיגון</a>
            </div>
          </div>
        </div>
        <hr className="space" />
        <div className="row copy-row">
          <div className="col-md-12 copy-text">
            © 2024 {settings?.siteName || 'Default Site Name'} - All rights reserved.
            <div className="comark-signature">
      <a
        href="https://www.comarkit.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          src="https://www.comarkit.com/delivery/footer.png"
          alt="לוגו COMARK"
          className="comark-logo"
        />
      </a>
      <div className="comark-text">
        <a
          href="https://www.comarkit.com/"
          target="_blank"
          className="comark-small-line"
        >
          קומרק ©
        </a>
        <a
          href="https://www.comarkit.com/"
          target="_blank"
          className="small-line"
        >
          בניית אתרים
        </a>
      </div>
      <br />
      <br />
      <br />
    </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
