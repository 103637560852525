import React from 'react';

const Specializing = () => {
  return (
    <div className="section-empty bg-white">
      <div className="container content">
        <div className="row" data-anima="" data-timeline="asc" data-timeline-time="300">
          <div className="col-md-3 anima fade-bottom" style={{ position: 'relative', animationDuration: '500ms', transitionTimingFunction: 'ease', transitionDelay: '0ms' }}>
            <div className="icon-box">
              <div className="icon-box-cell">
                <i className="im-pencil-ruler text-xl"></i>
              </div>
              <div className="icon-box-cell">
                <label className="text-m">תכנון ואפיון</label>
                <p className="text-s">בחירת מנוע מתאים לשער לפי הצורך</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 anima fade-bottom" style={{ position: 'relative', animationDuration: '500ms', transitionTimingFunction: 'ease', transitionDelay: '0ms' }}>
            <div className="icon-box">
              <div className="icon-box-cell">
                <i className="im-drill text-xl"></i>
              </div>
              <div className="icon-box-cell">
                <label className="text-m">התקנת מנועים</label>
                <p className="text-s">התקנת מנועים לשערי כנפיים ושערי </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 anima fade-bottom" style={{ position: 'relative', animationDuration: '500ms', transitionTimingFunction: 'ease', transitionDelay: '0ms' }}>
            <div className="icon-box">
              <div className="icon-box-cell">
                <i className="im-warehouse text-xl"></i>
              </div>
              <div className="icon-box-cell">
                <label className="text-m">תריסי גלילה</label>
                <p className="text-s">Complex customer works</p>
              </div>
            </div>
          </div>
          <div className="col-md-3 anima fade-bottom" style={{ position: 'relative', animationDuration: '500ms', transitionTimingFunction: 'ease', transitionDelay: '0ms' }}>
            <div className="icon-box">
              <div className="icon-box-cell">
                <i className="im-remote-controll text-xl"></i>
              </div>
              <div className="icon-box-cell">
                <label className="text-m">יצור והתקנת שערים</label>
                <p className="text-s">Sun, oil and gas</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Specializing;
