import React from 'react';

const Section = ({ title, text, items, buttonText }) => {
  return (
    <div className="col-md-6">
      <h2>{title}</h2>
      <p>{text}</p>
      <ul className="fa-ul text-s ul-dots">
        {items.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <a href="/article/1" className="btn btn-sm">{buttonText}</a>
    </div>
  );
};

export default Section;
