import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const MainBanner = ({ settings }) => {
  const [slides, setSlides] = useState([]);
  const [opacity, setOpacity] = useState(1);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [direction, setDirection] = useState(1);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (settings) {
      setSlides([
        { title: settings.footerText || 'Default Sentence 1' },
        { title: settings.sentence2 || 'Default Sentence 2' },
        { title: settings.sentence3 || 'Default Sentence 3' },
      ]);
    }
  }, [settings]);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.pageYOffset;
      const newOpacity = 1 - offset / 600;
      setOpacity(newOpacity < 0 ? 0 : newOpacity);
    };

    window.addEventListener('scroll', handleScroll);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (slides.length > 0) {
      const slideInterval = setInterval(() => {
        setDirection(1);
        setCurrentSlide((prev) => (prev === slides.length - 1 ? 0 : prev + 1));
      }, 3000);

      return () => {
        clearInterval(slideInterval);
      };
    }
  }, [slides]);

  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  };

  return (
    <div
      style={{
        backgroundImage: `url(${isMobile ? "images/EsgoBannerGorsel.jpg" : "images/EsgoBannerGorsel.jpg"})`,
        backgroundSize: 'cover',
        backgroundPosition: isMobile ? '30% center' : 'center',
        minHeight: isMobile ? '100vh' : '80vh',
        position: 'relative',
      }}
    >
      <div
        className="section-bg-image white ken-burn-center"
        style={{ opacity: opacity, position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
      >
        <div className="container content" style={{ position: 'relative', zIndex: 1 }}>
          <hr className="space" />
          <hr className="space" />
          
          <div className="row vertical-row" style={isMobile ? { margin: 0 } : {}}>
            <div className="col-md-6 position-relative bannerText" style={isMobile ? { padding: '0 20px', minHeight: '200px' } : { minHeight: '200px' }}>
              {slides.length > 0 && (
                <AnimatePresence custom={direction}>
                  <motion.div
                    className="slider"
                    style={{ position: 'absolute', top: isMobile ? '50px' : '0', left: 0, right: 0 }}
                    key={currentSlide}
                    custom={direction}
                    variants={variants}
                    initial="enter"
                    animate="center"
                    exit="exit"
                    transition={{
                      x: { type: 'spring', stiffness: 300, damping: 30 },
                      opacity: { duration: 0.2 },
                    }}
                  >
                    <h1 className="text-uppercase text-right-xs">
                      {slides[currentSlide].title}
                    </h1>
                  </motion.div>
                </AnimatePresence>
              )}
              <p className="text-right-xs" style={{ marginTop: isMobile ? '130px' : '90px', position: 'relative' }}>
                {settings?.footerText || 'Default Footer Text'}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainBanner;
