import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

const ArticlesSection = ({ articles }) => {
  return (
    <div className="section-empty bg-white mt-50 mb-50">
      <div className="container">
        <h1 className="text-center mb-4">תחומי עיסוק יס-מיגון</h1>
        <div className="row">
          {articles.map((article) => (
            <div className="col-lg-2 col-md-4 col-sm-6 col-6 mb-3" key={article.ID}>
              <div className="article-box" style={{ position: 'relative', height: '300px' }}>
              <Link to={`/article/${article.ID}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div 
                  style={{
                    backgroundImage: `url(${article.picture})`, // Set the background image
                    backgroundSize: 'cover', // Cover the whole area
                    backgroundPosition: 'center', // Center the image
                    height: '90%', // Full height
                    width: '100%', // Full width
                    borderRadius: '5px', // Optional: Add border-radius for better aesthetics
                    transition: '0.3s ease', // Transition for hover effects
                  }}
                />
                <div className="caption-inner" style={{
                  position: 'relative',
                  left: '0',
                  right: '0',
                  textAlign: 'right',
                  color: 'black',
                }}>
                 
                    <h4>{article.title}</h4>
                 
                </div>
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticlesSection;
