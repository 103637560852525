import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Modal, Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import { motion } from 'framer-motion';

const Header = ({ categories }) => {
  const [scroll, setScroll] = useState(false);
  const [navbarExpanded, setNavbarExpanded] = useState(false);
  const [topPosition, setTopPosition] = useState(60);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;

    if (scrollY > 60) {
      setTopPosition(0);
    } else {
      setTopPosition(60);
    }

    setScroll(scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleToggle = (expanded) => {
    setNavbarExpanded(expanded);
  };

  const handleMobileMenuToggle = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Contact form modal handlers
  const handleShowContactModal = () => setShowContactModal(true);
  const handleCloseContactModal = () => setShowContactModal(false);

  const handleContactFormSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here (e.g., sending data to an API)
    alert('Form submitted!'); // Placeholder alert
    handleCloseContactModal(); // Close the modal after submission
  };

  return (
    <>
      <motion.header
        animate={scroll ? 'animate' : ''}
        className={`fixed-top ${scroll ? 'bg-light scroll-css' : 'bg-transparent'} menu-transparent`}
        style={{
          top: `${topPosition}px`,
          transition: 'top 0.1s ease-in-out',
          height: '0 !important',
          left: 0,
          right: 0,
        }}
      >
        <Navbar expand="lg" onToggle={handleToggle} expanded={navbarExpanded} className={scroll ? 'scroll-css' : ''}>
          <Container>
            <motion.a
              href="/"
              initial={{ scale: 1 }}
              animate={{ scale: scroll ? 1.05 : 1 }}
              transition={{ duration: 0.2, ease: 'easeOut' }}
              className="navbar-brand"
            >
              <img
                className={`scroll-hide ${scroll ? 'hidden' : 'showed'}`}
                src="../images/logo-white.png"
                alt="logo"
              />
              <img
                className={`scroll-show ${scroll ? 'showed' : 'hidden'}`}
                src="../images/logo.png"
                alt="logo"
              />
            </motion.a>
            <Navbar.Toggle aria-controls="navbarNavDropdown" onClick={handleMobileMenuToggle} />
            <Navbar.Collapse id="navbarNavDropdown">
              <Nav as="ul" className="me-auto navbar-nav-RTL desktop-menu">
                <Nav.Item as="li">
                  <Nav.Link as={Link} to="/" onClick={() => setNavbarExpanded(false)}>ראשי</Nav.Link>
                </Nav.Item>
                {categories && categories.length > 0 &&
                  categories.map((category) => {
                    let categoryUrl = `/category/${category.ID}`;
                    if (category.link && category.link === "1") {
                      categoryUrl = `/article/${category.articleid}`;
                    }
                    return (
                      <Nav.Item as="li" key={category.ID}>
                        <Nav.Link as={Link} to={categoryUrl} onClick={() => setNavbarExpanded(false)}>
                          {category.Name}
                        </Nav.Link>
                      </Nav.Item>
                    );
                  })}
              </Nav>
              <Nav.Item className="custom-area desktop-menu">
                <Button onClick={handleShowContactModal} className="btn">צור קשר</Button>
              </Nav.Item>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        {/* Mobile Menu */}
        <div className={`mobile-menu ${isMobileMenuOpen ? 'open' : ''}`}>
          <Nav as="ul" className="me-auto">
            {categories && categories.length > 0 &&
              categories.map((category) => {
                let categoryUrl = `#category-${category.ID}`;
                if (category.link && category.link === "1") {
                  categoryUrl = `/article/${category.articleid}`;
                }
                return (
                  <Nav.Item as="li" key={category.ID}>
                    <Nav.Link as={Link} to={categoryUrl} onClick={() => { setIsMobileMenuOpen(false); setNavbarExpanded(false); }}>
                      {category.Name}
                    </Nav.Link>
                  </Nav.Item>
                );
              })}
          </Nav>
        </div>

        {/* Backdrop */}
        <div
          className={`mobile-menu-backdrop ${isMobileMenuOpen ? 'open' : ''}`}
          onClick={handleMobileMenuToggle}
        />

        {/* Contact Us Modal */}
        <Modal show={showContactModal} onHide={handleCloseContactModal}>
          <Modal.Header closeButton>
            <Modal.Title>צור קשר</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleContactFormSubmit}>
              <Form.Group controlId="formBasicName">
                <Form.Label>שם</Form.Label>
                <Form.Control type="text" placeholder="הכנס את שמך" required />
              </Form.Group>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>אימייל</Form.Label>
                <Form.Control type="email" placeholder="הכנס את האימייל שלך" required />
              </Form.Group>
              <Form.Group controlId="formBasicMessage">
                <Form.Label>הודעה</Form.Label>
                <Form.Control as="textarea" rows={3} placeholder="הכנס את ההודעה שלך" required />
              </Form.Group>
              <Button variant="primary" type="submit">שלח</Button>
            </Form>
          </Modal.Body>
        </Modal>
      </motion.header>
    </>
  );
};

export default Header;
